import React from "react";
import ReactDOM from "react-dom";

import firebase from "js/firebase";

// css
import "css/admin.scss";

//
// entry point
//

// firebase
firebase.initializeApp(window.baiFirebase.config);

// react
const root = document.createElement("div");
root.id = "root";
root.style.display = "contents";
document.body.appendChild(root);

// firebase dependent code
(async () => {
    // preload
    await import(/* webpackMode: "eager" */ "js/preload");

    const { default: Adapter } = await import(/* webpackMode: "eager" */ "js/core/storage/adapter");
    const { default: FirebaseAdapter } = await import(/* webpackMode: "eager" */ "js/core/storage/firebaseAdapter");
    Adapter.setDefaultClass(FirebaseAdapter);

    const { default: AdminApp } = await import(/* webpackMode: "eager" */ "js/react/views/Admin/adminApp");

    // render
    ReactDOM.render(<AdminApp appVersion={window.appVersion} />, root);
})();

