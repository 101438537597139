import { GlobalStateController } from "bai-react-global-state";
import moment from "moment";

import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces";
import { User } from "js/core/models/user";
import * as slack from "js/core/utilities/slack";
import { fetchWithAuth, FetchError } from "js/react/views/Admin/utils/fetchWithAuth";
import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";
import { AdminPermission } from "common/constants";
import { IEnterpriseInvite, BillingInterval } from "common/interfaces/models";
import db from "js/db";

const logger = getLogger(LogGroup.TEAMS);

export interface Admin {
    uid: string
    email: string
}

export interface EnrichedInvite extends IEnterpriseInvite {
    link?: string
    openInvoice?: {
        id: string
        amountDue: number
        paymentLink: string
        createdAt: number
        dueAt: number
        customerEmail?: string
    }
}

export interface Price {
    id: string
    nickname: string
    billingInterval: "year" | "month"
    currency: string
    amount: number
    createdAt: number
    isDefault?: boolean
}

export interface Filters {
    showOnlyMyInvites: boolean
}

export interface State {
    invites: EnrichedInvite[]
    prices: Price[]
    admins: Admin[]
    isInitialized: boolean
    fetching: boolean
    userAttributes: Record<string, any>
    filters: Filters
}

export interface ConvertOrgToEnterpriseRequest {
    organizationId: string
    priceId?: string
    pricePerSeat: number
    billingInterval: BillingInterval
    daysUntilInvoiceDue: number
    notifyInviter: boolean
    invitedBy: string
}

const initialState: State = {
    invites: [],
    prices: [],
    admins: [],
    isInitialized: false,
    fetching: false,
    userAttributes: {},
    filters: { showOnlyMyInvites: true }
};

class EnterpriseController extends GlobalStateController<State> {
    private _firebaseUser: Record<string, any>;
    private _handleShowDialog: (title: string, body: string, props?: Record<string, any>) => void;

    constructor(initialState) {
        super(initialState);

        this._firebaseUser = null;
        this._handleShowDialog = null;
    }

    private async _loadUser() {
        await db.updateCurrentUser(this._firebaseUser);

        app.user = new User({ id: this._firebaseUser.uid }, { autoLoad: false });
        if (!ds.hasBeenSetup) {
            ds.dummySetup();
        }
        await app.user.load();
        await this._updateState({ userAttributes: app.user.attributes });
    }

    private async _loadAdmins() {
        const admins = await fetchWithAuth(`/admin/admins?permissions=${encodeURIComponent([AdminPermission.OWNER, AdminPermission.ENTERPRISE_MANAGER].join(","))}`, { method: "GET" }, this._firebaseUser);
        await this._updateState({ admins });
    }

    public async initialize(firebaseUser: Record<string, any>, handleShowDialog: (title: string, body: string, props?: Record<string, any>) => void) {
        await this._updateState(() => initialState);

        this._firebaseUser = firebaseUser;
        this._handleShowDialog = handleShowDialog;

        try {
            await Promise.all([
                this._loadUser(),
                this.fetchInvitesData(false),
                this._loadAdmins()
            ]);
            await this._updateState({ isInitialized: true });
        } catch (err) {
            logger.error(err, "[EnterpriseController] initialize() failed");
            this._handleShowDialog("Error", `Server error: ${err.message}`);
        }
    }

    public async fetchInvitesData(handleErrors: boolean = true) {
        const { filters: { showOnlyMyInvites } } = this._state;

        await this._updateState({ fetching: true });

        try {
            const { invites, prices } = await fetchWithAuth(`/admin/enterprise-invites?showOnlyMyInvites=${showOnlyMyInvites}`, { method: "GET" }, this._firebaseUser);

            await this._updateState({ invites, prices });
        } catch (err) {
            if (!handleErrors) {
                throw err;
            }
            logger.error(err, "[EnterpriseController] fetchInvitesData() failed");
            this._handleShowDialog("Error", `Server error: ${err.message}`);
        } finally {
            await this._updateState({ fetching: false });
        }
    }

    public async deleteInvite(inviteId: string) {
        await this._updateState({ fetching: true });

        try {
            await fetchWithAuth("/admin/enterprise-invites", { method: "DELETE", body: JSON.stringify({ inviteId }) }, this._firebaseUser);

            await this.fetchInvitesData();
        } catch (err) {
            logger.error(err, "[EnterpriseController] deleteInvite() failed");
            this._handleShowDialog("Error", `Server error: ${err.message}`);
        } finally {
            await this._updateState({ fetching: false });
        }
    }

    public async updateInvite(inviteId: string, updates: Partial<IEnterpriseInvite>) {
        await this._updateState({ fetching: true });

        try {
            await fetchWithAuth("/admin/enterprise-invites", { method: "PUT", body: JSON.stringify({ inviteId, updates }) }, this._firebaseUser);

            await this.fetchInvitesData();
        } catch (err) {
            logger.error(err, "[EnterpriseController] updateInvite() failed");
            this._handleShowDialog("Error", `Server error: ${err.message}`);
        } finally {
            await this._updateState({ fetching: false });
        }
    }

    public async enableSlack() {
        await this._updateState({ fetching: true });

        try {
            await slack.enable();

            this._handleShowDialog("Success", "Slack integration enabled!");
        } catch (err) {
            if (err.message !== "popup_closed_by_user" && err.message !== "access_denied") {
                logger.error(err, "[EnterpriseController] enableSlack() failed");
                this._handleShowDialog("Error enabling Slack integration", err.message);
            }
        } finally {
            await this._updateState({ fetching: false, userAttributes: app.user.attributes });
        }
    }

    public async createInvite(invite: IEnterpriseInvite) {
        let invited = false;

        await this._updateState({ fetching: true });

        try {
            const { link } = await fetchWithAuth("/admin/enterprise-invites", { method: "POST", body: JSON.stringify(invite) }, this._firebaseUser);
            invited = true;

            await clipboardWrite({ [ClipboardType.TEXT]: link });

            if (invite.invitedBy !== this._firebaseUser.uid) {
                await this.updateFilters({ showOnlyMyInvites: false });
            }

            await this.fetchInvitesData();
        } catch (err) {
            if (err instanceof FetchError && err.status < 500) {
                this._handleShowDialog("Error", err.text);
            } else {
                logger.error(err, "[EnterpriseController] createInvite() failed");
                this._handleShowDialog("Error", `Server error: ${err.message}`);
            }
        } finally {
            await this._updateState({ fetching: false });
        }

        return invited;
    }

    public async updateFilters(filters: Filters) {
        await this._updateState({ fetching: true });

        try {
            await this._updateState({ filters });

            await this.fetchInvitesData(false);
        } catch (err) {
            if (err instanceof FetchError && err.status < 500) {
                this._handleShowDialog("Error", err.text);
            } else {
                logger.error(err, "[EnterpriseController] updateFilters() failed");
                this._handleShowDialog("Error", `Server error: ${err.message}`);
            }
        } finally {
            await this._updateState({ fetching: false });
        }
    }

    public async sendInvoiceByEmail(inviteId: string) {
        const invite = this._state.invites.find(invite => invite.id === inviteId);

        if (!invite || !invite.openInvoice) {
            return;
        }

        const shouldSend = await new Promise(resolve => {
            this._handleShowDialog(
                "Are you sure?",
                [
                    `Are you sure you want to send the invoice to ${invite.openInvoice.customerEmail}?`,
                    (invite.invoiceSentAt && invite.invoiceSentAt > invite.openInvoice.createdAt)
                        ? `Invoice was already emailed on ${moment(invite.invoiceSentAt).toLocaleString()}.`
                        : null
                ].filter(Boolean).join(" "),
                {
                    cancelButtonLabel: "Nevermind",
                    okButtonLabel: "Send",
                    onAccept: () => resolve(true),
                    onCancel: () => resolve(false)
                }
            );
        });

        if (!shouldSend) {
            return;
        }

        await this._updateState({ fetching: true });
        try {
            await fetchWithAuth("/admin/enterprise-invite-send-invoice", { method: "POST", body: JSON.stringify({ inviteId: invite.id }) }, this._firebaseUser);

            this._handleShowDialog("Success", "Invoice has been successfully sent!");

            await this.fetchInvitesData();
        } catch (err) {
            if (err instanceof FetchError && err.status < 500) {
                this._handleShowDialog("Error", err.text);
            } else {
                logger.error(err, "[EnterpriseController] sendInvoiceByEmail() failed");
                this._handleShowDialog("Error", `Server error: ${err.message}`);
            }
        } finally {
            await this._updateState({ fetching: false });
        }
    }

    public async convertOrgToEnterprise(request: ConvertOrgToEnterpriseRequest) {
        let converted = false;

        const shouldConvert = await new Promise(resolve => {
            this._handleShowDialog(
                "Are you sure?",
                `Are you sure you want to convert organization ${request.organizationId} to Enterprise?`,
                {
                    cancelButtonLabel: "Nevermind",
                    okButtonLabel: "Convert",
                    onAccept: () => resolve(true),
                    onCancel: () => resolve(false)
                }
            );
        });
        if (!shouldConvert) {
            return converted;
        }

        await this._updateState({ fetching: true });
        try {
            await fetchWithAuth("/admin/convert-org-to-enterprise", { method: "POST", body: JSON.stringify(request) }, this._firebaseUser);
            converted = true;

            if (request.invitedBy !== this._firebaseUser.uid) {
                await this.updateFilters({ showOnlyMyInvites: false });
            }

            await this.fetchInvitesData();

            this._handleShowDialog("Success", `Organization ${request.organizationId} has been successfully converted to Enterprise!`);
        } catch (err) {
            if (err instanceof FetchError && err.status < 500) {
                this._handleShowDialog("Error", err.text);
            } else {
                logger.error(err, "[EnterpriseController] convertOrgToEnterprise() failed");
                this._handleShowDialog("Error", `Server error: ${err.message}`);
            }
        } finally {
            await this._updateState({ fetching: false });
        }

        return converted;
    }
}

export default new EnterpriseController(initialState);
