import React from "react";
import styled from "styled-components";
import JSONPretty from "react-json-pretty";
import "../styles/blueprint_styles";
import { Button, Spinner } from "@blueprintjs/core";
import moment from "moment";

import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import { SupportedFirebaseTables } from "common/interfaces/models";
import MongoMigrationController from "../controllers/MongoMigrationController";

const Container = styled.div`    
    background: #e6e6e6;
    display: flex;
    flex-flow: column;
    width: 100%;
    color: #2d2d2d;
    max-height: calc(100vh - 58px);
    min-height: calc(100vh - 58px);
    overflow-y: auto;
    & .header {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        border-bottom: 1px solid #c2c2c2;
        padding: 20px;
        padding-bottom: 20px;
        font-size: 1.5em;
    }
    & .content {
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        justify-content: flex-start;
        border-bottom: 1px solid #c2c2c2;
        padding: 20px;
        gap: 20px;
        & .content-card {
            background: white;
            border: 1px solid #d6d6d6;
            border-radius: 3px;
            padding: 20px;
            gap: 10px;
            display: flex;
            flex-flow: column;
            min-width: 500px;
            width: 100%;
        }
    }
    & .table {
        display: flex;
        flex-flow: column;
        padding: 20px;     
        width: 100%;
        & .table-row {
            background: white;
            cursor: pointer;
            border: 1px solid #d6d6d6;
            border-radius: 3px;
            padding: 20px;
            gap: 40px;
            display: flex;
            flex-flow: row wrap;
            & .table-row-data:first-child {
                width: 15%;
            }
            & .table-row-data:nth-child(2) {
                width: 25%;
            }
            & .table-row-data:nth-child(3) {
                width: 25%;
            }
            & .table-row-expanded {
                margin-top: -20px;
                display: flex;
                flex-flow: row;
                min-height: 200px;
                width: 100%;
                gap: 20px;
                & .table-row-json-view {
                    background: #e6e6e6;
                    border: 1px solid #d6d6d6;
                    width: 50%;
                    overflow-x: auto;
                }
            }
        }
        & .table-empty-text {
            text-align: center;
            padding: 100px;
            color: #989898;
            font-size: 1.5em;
        }
    }
`;

class MongoMigration extends React.Component {
    state = {
        selectedTable: "all",
        selectedDocumentId: "",
        expandedMigrationEntryIds: [],
    };

    componentDidMount() {
        const { firebaseUser, handleShowDialog } = this.props;
        MongoMigrationController.initialize(firebaseUser, handleShowDialog);
    }

    handleFilter = () => {
        const { selectedTable, selectedDocumentId } = this.state;
        MongoMigrationController.fetchMongoMigrationEntries(selectedTable !== "all" && selectedTable, selectedDocumentId);
    };

    handleManualSync = () => {
        const { selectedTable, selectedDocumentId } = this.state;
        if (selectedTable === "all" && !selectedDocumentId) {
            this.props.handleShowDialog("Warning", "Please select a table or document to sync");
            return;
        }
        MongoMigrationController.triggerManualSync(selectedTable !== "all" && selectedTable, selectedDocumentId);
    }

    handleExpandMigrationEntry = id => {
        const { expandedMigrationEntryIds } = this.state;
        if (expandedMigrationEntryIds.includes(id)) {
            this.setState({ expandedMigrationEntryIds: expandedMigrationEntryIds.filter(entryId => entryId !== id) });
        } else {
            this.setState({ expandedMigrationEntryIds: [...expandedMigrationEntryIds, id] });
        }
    }

    renderMigrationEntries = () => {
        const { expandedMigrationEntryIds } = this.state;
        const { isFetching, mongoMigrationEntries } = this.props;

        if (isFetching) return <Spinner />;

        if (!mongoMigrationEntries || !mongoMigrationEntries.length) {
            return <div className="table-empty-text">No entries found</div>;
        }

        return mongoMigrationEntries.map(entry => {
            const { _id: id, firebaseTable, firebaseId, mongoId, latestFirebaseData, latestMongoData, lastSyncedAt } = entry;
            const isExpanded = expandedMigrationEntryIds.includes(id);
            return (
                <div className="table-row" onClick={() => this.handleExpandMigrationEntry(id)}>
                    <span className="table-row-data"><b>{firebaseTable}</b></span>
                    <span className="table-row-data">Firebase ID: <i>{firebaseId}</i></span>
                    <span className="table-row-data">Mongo ID: <i>{mongoId}</i></span>
                    <span className="table-row-data">Last synced at: {moment(lastSyncedAt).format("MMM:Do HH:mm:ss")}</span>
                    <div style={{ flexGrow: 2 }} />
                    <Button icon={isExpanded ? "chevron-up" : "chevron-down"} />
                    {isExpanded && (<>
                        <div style={{ flexBasis: "100%", height: 0 }} />
                        <div className="table-row-expanded">
                            <div className="table-row-json-view">
                                <span><b>Firebase Data</b></span>
                                <JSONPretty id="json-pretty" data={latestFirebaseData} />
                            </div>
                            <div className="table-row-json-view">
                                <span><b>Mongo Data</b></span>
                                <JSONPretty id="json-pretty" data={latestMongoData} />
                            </div>
                        </div>
                    </>)}
                </div>
            );
        });
    };

    render() {
        const options = ["all", ...Object.keys(SupportedFirebaseTables)].map(
            key => (<option key={key} value={key}>{key === "all" ? "All Supported Tables" : key}</option>)
        );

        return (
            <Container>
                <div className="header">Mongo migration management</div>
                <div className="content">
                    <div className="content-card">
                        <span>Filter Firebase to Mongo sync requests</span>
                        <div className="bp4-select">
                            <select onChange={e => this.setState({ selectedTable: e.target.value })}>{options}</select>
                        </div>
                        <div className="bp4-input-group">
                            <input
                                className="bp4-input"
                                placeholder="Firebase Document ID"
                                onChange={e => this.setState({ selectedDocumentId: e.target.value })}
                                type="text"
                            />
                        </div>
                        <button className="bp4-button bp4-intent-primary" onClick={this.handleFilter}>
                            Filter sync history
                        </button>
                        <button className="bp4-button bp4-intent-danger" onClick={this.handleManualSync}>
                            Trigger manual sync
                        </button>
                    </div>
                </div>
                <div className="table">
                    {this.renderMigrationEntries()}
                </div>
            </Container>
        );
    }
}

export default withFirebaseUser(
    MongoMigrationController.withState(MongoMigration)
);
