import React, { Component } from "reactn";
import PropTypes from "prop-types";
import { withStyles, withTheme } from "@material-ui/core/styles";

import InputButtonBlock from "../components/InputButtonBlock";
import MultiInputButtonBlock from "../components/MultiInputButtonBlock";
import { pageStyles } from "../styles/page_styles";
import firebase from "js/firebase";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";

class General extends Component {
    copyPresentationClicked = async presentationId => {
        const { handleShowDialog } = this.props;
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/copydeck", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ id: presentationId })
        });

        if (!response.ok) {
            handleShowDialog(
                "Error",
                response.status == 404
                    ? `The presentation id "${presentationId}" does not exist or is invalid`
                    : `${response.status} ${response.statusText}`
            );
            return null;
        }
        const result = await response.json();
        window.location.href = `${window.baseUrl}/${result.presentationId}`;
    };

    forceEmailVerification = async userEmailOrUid => {
        const { handleShowDialog } = this.props;
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/forceEmailVerification", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ userEmailOrUid })
        });

        if (!response.ok) {
            handleShowDialog(
                "Error",
                response.status == 404
                    ? `The user email or id "${userEmailOrUid}" does not exist or is invalid`
                    : `${response.status} ${response.statusText}`
            );
            return null;
        }
    };

    handleDeleteUser = async userEmailOrUid => {
        const { handleShowDialog } = this.props;
        const idToken = await this.props.firebaseUser.getIdToken();

        const res = await fetch("/admin/delete-user", {
            method: "DELETE",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ userEmailOrUid })
        });

        const { message } = await res.json();
        handleShowDialog(res.ok ? "User Successfully Deleted" : "Error", message);
    };

    handleDeleteOrg = async orgId => {
        const { handleShowDialog } = this.props;
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/delete-org", {
            method: "DELETE",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ id: orgId })
        });

        if (!response.ok) {
            handleShowDialog(
                "Error",
                response.status == 404
                    ? `The org id "${orgId}" does not exist or is invalid`
                    : `${response.status} ${response.statusText}`
            );
            return null;
        }
    }

    handleSetTrialEnd = async ({ userEmailOrUid, date, orgId }) => {
        const { handleShowDialog } = this.props;
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/set-trial", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                userEmailOrUid,
                orgId,
                date
            })
        });

        if (!response.ok) {
            handleShowDialog(
                "Error",
                response.status == 404
                    ? `Could not update trial end time for ${userEmailOrUid || orgId}`
                    : `${response.status} ${response.statusText}`
            );
            return null;
        }
    };

    generateInviteURLClicked = async userEmailOrUid => {
        const { handleShowDialog } = this.props;
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/generate-invite-url", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ userEmailOrUid })
        });
        if (!response.ok) {
            handleShowDialog(
                "Error",
                response.status == 404
                    ? `The user email or id "${userEmailOrUid}" does not exist or does not have a valid invite.`
                    : `${response.status} ${response.statusText}`
            );
            return null;
        }
        const result = await response.json();
        handleShowDialog("Invite URLS", result.urls);
    };

    decodeInviteClicked = async inviteCode => {
        const { handleShowDialog } = this.props;
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/decode-invite-token", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ token: inviteCode })
        });
        if (!response.ok) {
            handleShowDialog(
                "Error",
                response.status == 500
                    ? `The invite token ${inviteCode} is invalid or does not exist.`
                    : `${response.status} ${response.statusText}`
            );
            return null;
        }
        const result = await response.json();
        handleShowDialog(
            "Invite Data",
            <pre>{JSON.stringify(result.results, null, 2)}</pre>
        );
    };

    loginAsUser = async userEmailOrUid => {
        const { handleShowDialog } = this.props;
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/login-as-user", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ userEmailOrUid })
        });

        if (!response.ok) {
            handleShowDialog("Error", `${response.status} ${response.statusText}`);
            return null;
        }
        const { authToken, userId } = await response.json();
        // Sign out from firebase auth
        await firebase.auth().signOut();

        const removeEvent = firebase.auth().onAuthStateChanged(function(user) {
            if (user && user.uid === userId) {
                window.location.href = "/login";
                removeEvent();
            }
        });
        await firebase.auth().signInWithCustomToken(authToken);
    };

    handleUserPassword = async ({ userEmailOrUid, password }) => {
        const { handleShowDialog } = this.props;
        if (!userEmailOrUid || !password) {
            handleShowDialog(
                "Set password failed",
                `Both Uid/email and password required.`
            );
            return;
        }
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/setUserPassword", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ userEmailOrUid, password })
        });

        if (!response.ok) {
            handleShowDialog(
                "Set password failed",
                `Error: ${response.status} ${response.statusText}`
            );
        } else {
            handleShowDialog(
                `Password was updated for ${userEmailOrUid}`,
                `${userEmailOrUid} has a new password now.`
            );
        }
        return await response.json();
    };

    handleOrgDomain = async ({ orgId, domain }) => {
        const { handleShowDialog } = this.props;
        if (!orgId || !domain) {
            handleShowDialog(
                "Set org domain failed",
                `Both orgId and domain required.`
            );
            return;
        }
        const idToken = await this.props.firebaseUser.getIdToken();
        const response = await fetch("/admin/setOrgDomain", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ orgId, domain })
        });

        if (!response.ok) {
            handleShowDialog(
                "Set org domain failed",
                `Error: ${response.status} ${response.statusText}`
            );
        } else {
            handleShowDialog(
                `Domain was updated for ${orgId}`,
                `${domain} can now be used for SSO.`
            );
        }
        return await response.json();
    };

    handleSessionTrackingToggle = async (userEmailOrUid, sessionTrackingDisabled) => {
        const { firebaseUser, handleShowDialog } = this.props;

        const idToken = await firebaseUser.getIdToken();
        const response = await fetch("/admin/session-tracking", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${idToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ userEmailOrUid, sessionTrackingDisabled })
        });

        if (!response.ok) {
            if (response.status === 404) {
                handleShowDialog(
                    "User not found",
                    `User with email or uid ${userEmailOrUid} not found`
                );
                return;
            }

            const { message } = await response.json();
            handleShowDialog(
                "Error",
                message
            );
            return;
        }

        handleShowDialog(
            "Success",
            `Session tracking for user ${userEmailOrUid} was successfully ${sessionTrackingDisabled ? "disabled" : "enabled"}`
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.page}>
                <h2 className={classes.pageTitle}>General Page</h2>
                <div className={[classes.ScrollableblockGrid]}>
                    <InputButtonBlock
                        buttonLabel="Copy Presentation"
                        description="Note - You should copy a presentation instead of being added as a collaborator"
                        title="Copy Presentation"
                        placeholder="Presentation ID or Link ID"
                        handleClick={this.copyPresentationClicked}
                    />
                    <InputButtonBlock
                        buttonLabel="Login as user"
                        description="Login as user"
                        title="Login as user"
                        placeholder="User ID or Email"
                        handleClick={this.loginAsUser}
                    />
                    <InputButtonBlock
                        buttonLabel="Verify Email"
                        description="This will force email verification"
                        title="Verify Email"
                        placeholder="User ID or Email"
                        handleClick={this.forceEmailVerification}
                    />
                    <InputButtonBlock
                        buttonLabel="Generate Invite URL"
                        description="Note - The user must have a valid invite for this to work"
                        title="Invite URL Generator"
                        placeholder="User ID or Email"
                        handleClick={this.generateInviteURLClicked}
                    />
                    <InputButtonBlock
                        buttonLabel="Decode Invite Token"
                        description="This will decode an invite token"
                        title="Invite Token Decoder"
                        placeholder="Token"
                        handleClick={this.decodeInviteClicked}
                    />
                    <MultiInputButtonBlock
                        buttonLabel="Set User Password"
                        description="This will change a users password"
                        title="User Password"
                        inputs={[
                            {
                                key: "userEmailOrUid",
                                placeholder: "User ID or Email"
                            },
                            {
                                key: "password",
                                type: "password",
                                placeholder: "Password"
                            }
                        ]}
                        handleClick={this.handleUserPassword}
                    />
                    <MultiInputButtonBlock
                        buttonLabel="Set User Trial End"
                        description="This will set a Pro Trial End Date For User (In server timezone)"
                        title="Set User Trial End Date"
                        inputs={[
                            {
                                key: "userEmailOrUid",
                                placeholder: "User ID or Email"
                            },
                            {
                                key: "date",
                                type: "datetime",
                                placeholder: "Date in server timezone"
                            }
                        ]}
                        handleClick={this.handleSetTrialEnd}
                    />
                    <MultiInputButtonBlock
                        buttonLabel="Set Org Trial End"
                        description="This will set a Trial End Date For an Org (In server timezone)"
                        title="Set Org Trial End Date"
                        inputs={[
                            {
                                key: "orgId",
                                placeholder: "Org ID"
                            },
                            {
                                key: "date",
                                type: "datetime",
                                placeholder: "Date in server timezone"
                            }
                        ]}
                        handleClick={this.handleSetTrialEnd}
                    />
                    <MultiInputButtonBlock
                        buttonLabel="Set Org Domain"
                        description="This will change an org's domain for SSO"
                        title="Organization Domain"
                        inputs={[
                            {
                                key: "orgId",
                                placeholder: "Org ID"
                            },
                            {
                                key: "domain",
                                placeholder: "Domain"
                            }
                        ]}
                        handleClick={this.handleOrgDomain}
                    />
                    <InputButtonBlock
                        buttonLabel="Delete User"
                        description="This will delete a user and all of their data"
                        title="Delete User"
                        placeholder="User ID or Email"
                        handleClick={this.handleDeleteUser}
                    />
                    <InputButtonBlock
                        buttonLabel="Delete Organization"
                        description="This will delete a organization and all of its data, only use this when the org has canceled stripe plan!!"
                        title="Delete Organization"
                        placeholder="Organization ID"
                        handleClick={this.handleDeleteOrg}
                    />
                    <InputButtonBlock
                        buttonLabel="Disable"
                        description="Disable session tracking for user"
                        title="Disable session tracking"
                        placeholder="User ID or Email"
                        handleClick={userEmailOrUid => this.handleSessionTrackingToggle(userEmailOrUid, true)}
                    />
                    <InputButtonBlock
                        buttonLabel="Enable"
                        description="Enable session tracking for user"
                        title="Enable session tracking"
                        placeholder="User ID or Email"
                        handleClick={userEmailOrUid => this.handleSessionTrackingToggle(userEmailOrUid, false)}
                    />
                </div>
            </div>
        );
    }
}

General.propTypes = {
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};

export default withFirebaseUser((withTheme(withStyles(pageStyles)(General))));
